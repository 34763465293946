import ThemeContrast from './ThemeContrast';
import ThemeRtlLayout from './ThemeRtlLayout';
import ThemeColorPresets from './ThemeColorPresets';
import SettingsDrawer from './drawer';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};
export default function ThemeSettings({
  children
}: Props) {
  return <ThemeColorPresets data-sentry-element="ThemeColorPresets" data-sentry-component="ThemeSettings" data-sentry-source-file="ThemeSettings.tsx">
      <ThemeContrast data-sentry-element="ThemeContrast" data-sentry-source-file="ThemeSettings.tsx">
        <ThemeRtlLayout data-sentry-element="ThemeRtlLayout" data-sentry-source-file="ThemeSettings.tsx">
          {children}
          {/* <SettingsDrawer /> */}
        </ThemeRtlLayout>
      </ThemeContrast>
    </ThemeColorPresets>;
}