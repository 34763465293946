import { alpha } from '@mui/material/styles';
export type ColorSchema =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#EF7484',
  mainLight: '#e6a3ac',
  mainLight1: '#FBEBED',
  mainLight2: '#FAD5DA',
  dark: '#EF7484',
  dark1: '#AD545F',
  darker: '#005249',
  contrastText: '#FFFFFF',
  creamLight: '#ffefe9',
  bg: 'rgb(234,223,217)',
  creamLight2: 'rgba(108, 93, 126, 0.12)',
};

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#FFFFFF',
};

// const SECONDARY2 = {
//   lighter: 'hsl(220, 44%, 92%)',
//   light: 'hsl(222, 44%, 76%)',
//   main: 'hsl(225, 44%, 60%), 100%, 60%)',
//   dark: 'hsl(228, 44%, 41%)',
//   darker: 'hsl(231, 44%, 26%)',
//   contrastText: '#FFFFFF',
// };

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#D8FBDE',
  light: '#86E8AB',
  main: '#36B37E',
  dark: '#1B806A',
  darker: '#0A5554',
  contrastText: '#FFFFFF',
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

const COMMON = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
    paleGrey: '#ece2d9',
    silver: '#dbd7d7',
    darkSilver: '#849eb2',
    lightSilver: '#C0CED9',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  // secondary2: SECONDARY2,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  lightGrey: 'rgba(50, 93, 127, 0.08)',
  lightBlueGrey: 'rgba(132, 158, 178, 1)',
  paleBlueGrey: '#849EB2',

  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  button: {
    selectedBackground: '#10202C',
    shadow: '0px 10px 51px -11px rgba(239,116,132,0.75)',
    hovered: '#CD616F',
    secondaryHovered: '#7790a3',
    secondaryShadow: '0px 10px 51px -9px rgba(119,144,163,0.75)',
    tertiaryHovered: '#e8e8e8',
    tertiaryShadow:
      '0px 10px 51px -9px 0px 10px 51px -9px rgba(232,232,232,0.75)',
  },
  header: {
    background: '#FBEEE8',
  },
};

export default function palette(themeMode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      secondaryLight: `#8A96A1`,
      off: '#BAB9B9',
      disabled: GREY[500],
      black: '#212B36',
      link: '#335D7F',
    },
    stepper: {
      connector: '#eaeaf0',
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      grey: '#EDEEEF',
      grey1: GREY[400],
      darkGrey: GREY[800],
      transGrey: 'rgba(145, 158, 171, 0.08)',
      neutral: GREY[200],
      black: '#000000',
      off: '#B8B8B8',
      off1: '#B8B8B8',
      off2: '#DFE3E7',
      main: '#fbede6',
      manish: '#325D7F',
      review: '#FFFBFA',
    },
    action: {
      ...COMMON.action,
      active: GREY[600],
      light: {
        hover: '#F7F8F9',
      },
    },
  } as const;

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      secondaryLight: `#8A96A1`,
      disabled: GREY[600],
      disabledLight: '#ddd5d4',
      off: '#BAB9B9',
      black: '#212B36',
      link: '#335D7F',
    },
    stepper: {
      connector: '#eaeaf0',
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.16),
      black: '#000000',
      main: '#fbede6',
      manish: '#325d7f',
      off2: '#DFE3E7',
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
      light: {
        hover: '#F7F8F9',
      },
    },
  } as const;

  return themeMode === 'light' ? light : dark;
}
