import { createSlice } from '@reduxjs/toolkit';
// import { ordersApi } from '../../../../../admin/app/screens/orders/services/ordersApi';

const paymentSlice: any = createSlice({
  name: 'payment',
  initialState: {
    lastTotalPrice: null,
  },
  reducers: {
    setLastTotalPrice: (state, action) => {
      state.lastTotalPrice = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //     builder
  //         .addMatcher(
  //             ordersApi.endpoints.orders.matchFulfilled,
  //             (state:any, { payload }) => {
  //                 state.orders = payload
  //             }
  //         )
  //         .addMatcher(
  //             ordersApi.endpoints.chatInboxUsers.matchFulfilled,
  //             (state:any, { payload }) => {
  //                 state.chatInboxUsers = payload
  //             }
  //         )
  //         .addMatcher(
  //             ordersApi.endpoints.messages.matchFulfilled,
  //             (state:any, { payload }) => {
  //                 state.messages = payload
  //             }
  //         )
  // },
});

export const { setLastTotalPrice } = paymentSlice.actions;

export default paymentSlice.reducer;
