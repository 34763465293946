import { useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeOptions, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { useSettingsContext } from '../../screens/shared/components/settings';
import palette from './palette';
import typography from './typography';
import shadows from './shadows';
import componentsOverride from './overrides';
import customShadows from './customShadows';
import GlobalStyles from './globalStyles';
import breakpoints from './breakpoints';
type Props = {
  children: React.ReactNode;
};
export default function ThemeProvider({
  children
}: Props) {
  const {
    themeMode,
    themeDirection
  } = useSettingsContext();
  const themeOptions: ThemeOptions = useMemo(() => ({
    palette: palette(themeMode),
    typography,
    shape: {
      borderRadius: 8
    },
    direction: themeDirection,
    shadows: shadows(themeMode),
    customShadows: customShadows(themeMode),
    breakpoints
  }), [themeDirection, themeMode]);
  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);
  return <MUIThemeProvider theme={theme} data-sentry-element="MUIThemeProvider" data-sentry-component="ThemeProvider" data-sentry-source-file="index.tsx">
      <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="index.tsx" />
      <GlobalStyles data-sentry-element="GlobalStyles" data-sentry-source-file="index.tsx" />
      {children}
    </MUIThemeProvider>;
}