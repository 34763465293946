import { api } from '~services/index';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createVisitor: builder.mutation({
      query: (body) => {
        return {
          url: `/api/auth/create-visitor`,
          method: 'POST',
          body,
        };
      },
    }),
    login: builder.mutation({
      query: (body) => {
        return {
          url: `/api/auth/login`,
          method: 'POST',
          body,
        };
      },
    }),
    register: builder.mutation({
      query: (body) => {
        return {
          url: `/api/auth/register`,
          method: 'POST',
          body,
        };
      },
    }),
    verifyAccount: builder.mutation({
      query: (body) => {
        return {
          url: `/api/auth/verify-account`,
          method: 'PATCH',
          body,
        };
      },
    }),
    sendOtp: builder.mutation({
      query: (id) => {
        return {
          url: `/api/auth/send-otp/${id}`,
        };
      },
    }),
    forgotPassword: builder.mutation({
      query: (email) => {
        return {
          url: `/api/auth/forgot-password/${email}`,
        };
      },
    }),
    resetPassword: builder.mutation({
      query: (body) => {
        return {
          url: `/api/auth/reset-password`,
          method: 'PATCH',
          body,
        };
      },
    }),
    changePassword: builder.mutation({
      query: (body) => {
        return {
          url: `/api/auth/change-password`,
          method: 'PATCH',
          body,
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useSendOtpMutation,
  useRegisterMutation,
  useCreateVisitorMutation,
  useVerifyAccountMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useForgotPasswordMutation,
} = authApi;
