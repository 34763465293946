import { LazyMotion, m } from 'framer-motion';

// ----------------------------------------------------------------------

// eslint-disable-next-line import/extensions
const loadFeatures = () => import('./features.js').then(res => res.default);
type Props = {
  children: React.ReactNode;
};
export default function MotionLazyContainer({
  children
}: Props) {
  return <LazyMotion strict features={loadFeatures} data-sentry-element="LazyMotion" data-sentry-component="MotionLazyContainer" data-sentry-source-file="MotionLazyContainer.tsx">
      <m.div style={{
      height: '100%'
    }} data-sentry-element="unknown" data-sentry-source-file="MotionLazyContainer.tsx"> {children} </m.div>
    </LazyMotion>;
}